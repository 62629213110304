import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';


import { apiRequest } from "../../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../../redux/actions";
import Button from "react-bootstrap/esm/Button";

import moment from "moment"
import BDMDashboard from "./BDMDashboard";
import {LABEL_EVENT} from "../../econf/cnf"
import { dynamicSortMultiple, oValues, productValueLabel } from "../_tools";

class EBSDashboard extends Component {
    state = {
        "productFilter": "",
        "eventFilter": {value:0, label:"Select " + LABEL_EVENT},
        "editionFilter": {value:0, label:"Select edition"},
        "showType":{value:0, label:"All"},
        "showMember":{value:0, label:"All"},
    }

    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }

    setEdition (eID)
    {
        if (eID in this.props.productConfig.editionsDic)
        {
            var cEdition = this.props.productConfig.editionsDic[eID]
            this.setState({ productFilter: cEdition.shortName, editionFilter: {value:cEdition.id, label:cEdition.subname} });
            return
        }
        this.setState({ productFilter: "" });
    }

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
        if (inp.name === "eventFilter")
        {
            this.setState({ productFilter: "", editionFilter: {value:0, label:"Select edition"}});
            if (e.value in this.props.productConfig.eventsDic)
            {
                var cEvent = this.props.productConfig.eventsDic[e.value]
                if (cEvent.idEditions.length > 0)
                {
                    for (var ed of cEvent.idEditions)
                        if (this.props.productConfig.editionsDic[ed].isCurrent)
                        {
                            this.setEdition (ed)
                            break
                        }
                }
            }
        }
        if (inp.name === "editionFilter")
        {
            this.setEdition (e.value)
        }
    };

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render () {
        var that = this

        var salesUpdateLines = null
        var totalSales = 0
        if (this.props.sales && (Object.keys(this.props.sales).length > 0))
            salesUpdateLines = oValues(this.props.sales).sort (dynamicSortMultiple('-transactionDateYMD', '-agreedPrice', 'createdAtYMD')).map (function (p, ic) {
                if (that.state.productFilter.length < 5)
                    return null
                var hasSelProduct = (p.oneTimeProduct && ( p.oneTimeProduct.toLowerCase().includes (that.state.productFilter.toLowerCase())) )
                for (var pp of p.products)
                    hasSelProduct = hasSelProduct || ( productValueLabel(pp.idProduct, that.props.productConfig).label.toLowerCase().includes (that.state.productFilter.toLowerCase()) )
                if ((that.state.productFilter.length > 0) && (!hasSelProduct))
                    return null
                var pCompany = (p.idCompany > 0) ? that.props.contactConfig.companiesDic[p.idCompany] : {}

                if (((that.state.showMember.value === 1) && (!pCompany.memberUntil)) || ((that.state.showMember.value === 2) && (pCompany.memberUntil)))
                    return null

                var prices = []
                var matchedProducts = []
                if (p.oneTimeProduct && ( p.oneTimeProduct.toLowerCase().includes (that.state.productFilter.toLowerCase())) )
                {
                    totalSales += p.agreedPrice
                    prices = [that.numberWithSpaces(p.agreedPrice)]
                    matchedProducts = [p.oneTimeProduct]
                }
                else
                {
                    for (var pp of p.products)
                        if ( productValueLabel(pp.idProduct, that.props.productConfig).label.toLowerCase().includes (that.state.productFilter.toLowerCase()) )
                        {
                            totalSales += pp.agreedPrice
                            prices.push (that.numberWithSpaces(pp.agreedPrice))
                            matchedProducts.push (productValueLabel(pp.idProduct, that.props.productConfig).sublabel)
                        }
                }
                return <tr key={"su"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingSaleId", "paramValue": p.id})}}>
                    <td>{pCompany.shortName}</td>
                    <td>{pCompany.memberUntil ? <i className={"fs-4 bi-check2"}/>:null}</td>
                    <td></td>
                    <td>{prices.join (' + ')}</td>
                    <td></td>
                    <td></td>
                    <td>{matchedProducts.join (' + ')}</td>
                    <td>{(p.idBy > 0) ? that.props.users[p.idBy].fullName : p.historicBy }</td>
                </tr>
            })
        
        var tasksUpdateLinesWithProposal = null
        var tasksUpdateLines = null
        var totalTasks = 0
        var totalHotTasks = 0
        var tasksUpdateLinesNumber = 0
        if (this.props.tasks && (Object.keys(this.props.tasks).length > 0))
        {
            for (var tid of Object.keys(this.props.tasks))
            {
                var t = this.props.tasks[tid]
                var tContact = (t.idContact > 0) ? that.props.contactConfig.contactsDic[t.idContact] : {idCompany:0}
                t.hotQualified = t['hotState'] || (tContact ?  ((tContact.idCompany > 0) && that.props.contactConfig.companiesDic[tContact.idCompany].isMember) : false)
            }

            tasksUpdateLinesWithProposal = oValues (this.props.tasks).filter (function(t) {
                return (!t.closeDate) && (t.isSalesTask)
            }).sort (dynamicSortMultiple('-hotState', '-hotQualified', '-proposalAmount')).map (function (p, ic) {
                if (!p.proposalAmount)
                    return null
                if (that.state.productFilter.length < 5)
                    return null
                
                if ((that.state.productFilter.length > 0) && (! productValueLabel(p.idProduct, that.props.productConfig).label.toLowerCase().includes (that.state.productFilter.toLowerCase()) ) )
                    return null

                var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {idCompany:0}
                var pCompany = (pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany] : {}
                if (p.hotState || pCompany.isMember)
                    totalHotTasks += p.proposalAmount
                else
                    totalTasks += p.proposalAmount
                
                if (((that.state.showMember.value === 1) && (!pCompany.isMember)) || ((that.state.showMember.value === 2) && (pCompany.isMember)))
                    return null
            
                
                return <tr className={p.hotState ? "task hot": "task"}  key={"tu"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                    <td>{pCompany.shortName}</td>
                    <td>{pCompany.isMember ? <i className={"fs-4 bi-check2"}></i>:null}</td>
                    <td>{p.forMembership ? <i className={"fs-4 bi-check2"}></i>:null}</td>
                    <td></td>
                    <td>{(p.hotState || pCompany.isMember) ? that.numberWithSpaces(p.proposalAmount):null }</td>
                    <td>{(p.hotState || pCompany.isMember) ? null:that.numberWithSpaces(p.proposalAmount) }</td>
                    <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).sublabel : ""} </td>
                    <td>{(p.idUser > 0) ? that.props.users[p.idUser].fullName : null}</td>
                </tr>
            })
            tasksUpdateLines = oValues (this.props.tasks).filter (function(t) {
                return (!t.closeDate) && (t.isSalesTask)
            }).sort (dynamicSortMultiple('-hotState', '-hotQualified')).map (function (p, ic) {
                if (p.proposalAmount)
                    return null
                
                if (that.state.productFilter.length < 5)
                    return null
                
                if ((that.state.productFilter.length > 0) && (! productValueLabel(p.idProduct, that.props.productConfig).label.toLowerCase().includes (that.state.productFilter.toLowerCase()) ))
                    return null
                tasksUpdateLinesNumber += 1

                var pContact = (p.idContact > 0) ? that.props.contactConfig.contactsDic[p.idContact] : {idCompany:0}
                var pCompany = pContact ? ((pContact.idCompany > 0) ? that.props.contactConfig.companiesDic[pContact.idCompany] : {}) : {}
                

                if (((that.state.showMember.value === 1) && (!pCompany.isMember)) || ((that.state.showMember.value === 2) && (pCompany.isMember)))
                    return null
                

                if (p.hotState)
                    totalHotTasks += p.proposalAmount
                else
                    totalTasks += p.proposalAmount
                return <tr className={p.hotState ? "task hot": "task"}  key={"tu"+ic} role="button" onClick={(e)=>{e.stopPropagation(); that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingTaskId", "paramValue": p.id})}}>
                    <td>{pCompany.shortName}</td>
                    <td>{pCompany.isMember ? <i className={"fs-4 bi-check2"}></i>:null}</td>
                    <td>{p.forMembership ? <i className={"fs-4 bi-check2"}></i>:null}</td>
                    <td></td>
                    <td>{p.hotState ? <i className={"fs-4 bi-check2"}></i>:null }</td>
                    <td>{that.numberWithSpaces(p.proposalAmount)}</td>
                    <td>{p.idProduct > 0 ? productValueLabel(p.idProduct, that.props.productConfig).sublabel : ""} </td>
                    <td>{(p.idUser > 0) ? that.props.users[p.idUser].fullName : null}</td>
                </tr>
            })
        }

        return <div className="EBSDashboard">
            {(salesUpdateLines || tasksUpdateLines || tasksUpdateLinesWithProposal) ? <div className={"mt-3 salesUpdate" + (that.props.interface.plEditionId ? " noPrint" : " doPrint ")} id="salesUpdate">
                <h3>Sales update - {moment().format("DD/MM/YYYY")} {this.state.productFilter.length > 0 ? (' - "' + this.state.productFilter + '"'):""}</h3>
                <div className="noPrint">
                    Filter: 
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="eventFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["eventFilter"]}
                        options={[{value:0, label:"Select event"}].concat (oValues (this.props.productConfig.eventsDic).sort (dynamicSortMultiple('*name')).map (function (d) { return {value:d.id, label:d.name} })) }
                    />
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="editionFilter"
                        onChange={that.handleSelectChange}
                        value={that.state["editionFilter"]}
                        options={[{value:0, label:"Select edition"}].concat (oValues(this.props.productConfig.editionsDic).filter (function (d) { return d.idEvent === that.state.eventFilter.value } ).sort (dynamicSortMultiple('*subname')).map (function (d) { return {value:d.id, label:d.subname} })) }
                    />
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="showType"
                        onChange={that.handleSelectChange}
                        value={that.state["showType"]}
                        options={[{value:0, label:"All"}, {value:1, label: "Signed-hot-offer"}]}
                    />
                    <Select 
                        className="d-inline-block ms-3"
                        menuPosition="fixed"
                        name="showMember"
                        onChange={that.handleSelectChange}
                        value={that.state["showMember"]}
                        options={[{value:0, label:"All"}, {value:1, label: "Only members"}, {value:2, label: "Only not members"}]}
                    />
                    <input
                        className="form-control me-3 d-inline-block d-none"
                        name="productFilter"
                        onChange={that.handleInputChange}
                        value={that.state["productFilter"]}
                        type="text"
                        placeholder="Product"
                    />
                    
                </div>
                {(that.state.productFilter.length >= 5) ? <Table responsive className="table-hover tasksTable">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Is member</th>
                            <th>To become member</th>
                            <th>Signed</th>
                            <th>Hot</th>
                            <th>Offer</th>
                            <th>Products</th>
                            <th>Manager</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="totalLine">
                            <td>TOTAL</td>
                            <td></td>
                            <td></td>
                            <td>{that.numberWithSpaces(totalSales)}</td>
                            <td>{that.numberWithSpaces(totalHotTasks)}</td>
                            <td>{that.numberWithSpaces(totalTasks)}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        {salesUpdateLines}
                        {(salesUpdateLines && (salesUpdateLines.length > 0)) ? <div className="endsWithPageBreak"></div>:null}
                        {tasksUpdateLinesWithProposal}
                        {(tasksUpdateLinesWithProposal && (tasksUpdateLinesWithProposal.length > 0)) ? <div className="endsWithPageBreak"></div>:null}
                        <tr className="totalLine">
                            <td>TOTAL</td>
                            <td></td>
                            <td></td>
                            <td>{that.numberWithSpaces(totalSales)}</td>
                            <td>{that.numberWithSpaces(totalHotTasks)}</td>
                            <td>{that.numberWithSpaces(totalTasks)}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        {(that.state.showType.value === 0) ? tasksUpdateLines:null}
                        {((that.state.showType.value === 0) && tasksUpdateLines && (tasksUpdateLinesNumber)) ? <tr className="totalLine printOnly">
                            <td>TOTAL</td>
                            <td></td>
                            <td></td>
                            <td>{that.numberWithSpaces(totalSales)}</td>
                            <td>{that.numberWithSpaces(totalHotTasks)}</td>
                            <td>{that.numberWithSpaces(totalTasks)}</td>
                            <td></td>
                            <td></td>
                        </tr>:null}
                    </tbody>
                </Table>:<h5 className="mt-3">Please select the {LABEL_EVENT} and edition above to see the update information</h5>}
            </div>: null}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        isAdmin: state.data.profile.isAdmin,
        profile: state.data.profile,
        announcements: state.data.announcements,
        productConfig: state.data.productConfig,
        contactConfig: state.data.contactConfig,
        sales: state.data.sales,
        tasks: state.data.tasks,
        users: state.data.users
    }
}

export default connect(mapStateToProps)(EBSDashboard)