import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';

import { apiRequest } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

import {dynamicSortMultiple, oValues, firstSortedValue} from "./_tools"

class ProductsBrowser extends Component {
    state = {
        selectedTab: "Products"
    }

    productsList = []

    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        return parts.join(".");
    }


    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'getProducts'}))
    }

    render () {
        var contents = null
        var that = this
        this.productsList = oValues (this.props.productConfig.productsDic).sort (dynamicSortMultiple ('fullName'))
        if (this.state.selectedTab === 'Products')
        {
            var pEvent = null
            var lines = this.productsList.map (function (p, ic) {
                var similar = p.idEventEdition === pEvent
                pEvent = p.idEventEdition
                return <tr key={"product"+ic} className={similar ? "tableLine groupped":"tableLine subheader"} >
                    <td>{similar ? "" : that.props.productConfig.editionsDic[p.idEventEdition].fullName}</td>
                    <td>{p.name}</td>
                    <td>{p.id} - {p.codeArticle}</td>
                    <td>{p.codeAnalytic}</td>
                    <td>{that.numberWithSpaces(p.price)}</td>
                    {(that.props.isAdmin || that.props.canEditProducts) ? <td><Button onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingProductId", "paramValue": p.id})}}>Edit</Button></td>:null}
                </tr>
            })
            contents = <div className="mt-3">
                <Button className="float-end btn-primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "editingProductId", "paramValue": 0})}}>+ Add</Button>
                <h3>Products</h3>
                <Table responsive className="table-hover listingTable">
                    <thead>
                        <tr>
                            <th>Event edition</th>
                            <th>Product title</th>
                            <th>Code</th>
                            <th>Code analytic</th>
                            <th>Price</th>
                            {(that.props.isAdmin || that.props.canEditProducts) ? <th>Actions</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {lines}
                    </tbody>
                </Table>
            </div>
        }

        return <div className="productsBrowser">
            {contents}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        productConfig: state.data.productConfig,
        isAdmin: state.data.profile.isAdmin,
        canEditProducts: state.data.profile.canEditProducts
    }
}

export default connect(mapStateToProps)(ProductsBrowser)