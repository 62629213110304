import { createRef, Component } from 'react';
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { MODIFY_INTERFACE_PARAM } from '../redux/actions';
import { apiRequest, ON_LOAD_EDITION, ON_LOAD_EVENT } from '../redux/actionsAPI';
import {LABEL_EVENT,LABEL_QNOTE} from '../econf/cnf'
import { productValueLabel } from './_tools';



class EventEditionPLModal extends Component {
    state =  {
        newLineCaption: "",
        newLineAmount: 0
    }
    
    componentDidMount() {
        if (this.props.interface.plEditionId > 0)
        {
            this.props.dispatch (apiRequest({'endpoint':'getPL', 'editionId': this.props.interface.plEditionId}))    
        }
    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };

    numberWithSpaces(x) {
        if (!x)
            return x
        if (Math.abs (x) < 0.01)
            return 0
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
        if ((parts.length > 1) && (parts[1].length > 2))
            parts[1] = parts[1].substr (0,2)
        return parts.join(".");
    }

    render () {
        var that = this
        
        var debit = []
        var debTotal = 0
        var credit = []
        var creditTotal = 0
        if (that.props.plDetails)
        {
            that.props.plDetails.horusEntries.map (function (comp, ic) {
                var lines = []
                var companyTotal = 0
                console.log (comp)

                comp.map (function (entry, icc) {
                    if (!entry.isSales)
                    {
                        var val = (entry.costLine.Credit ? entry.costLine.Credit : 0) - (entry.costLine.Debit ? entry.costLine.Debit : 0)
                        debTotal += val
                        companyTotal += val
                        console.log (val, companyTotal)
                        
                        lines.push (<div className='costLine' key={"dcl" + ic + "_" + icc}>
                                <div className='invoiceNumber'>{entry.accountEntry.DocumentNo}<div className='hoverHint'>Date: {entry.accountEntry.Date}<br/>
                                                                                                                        Last modified: {entry.accountEntry.LastModifiedDate}<br/>
                                                                                                                        Confirmed:{entry.accountEntry.Confirmed ? "Yes":"No"}</div></div>
                                <div className='amount'>{that.numberWithSpaces(val)}</div>
                        </div>)
                    }
                })
                if (lines.length > 0)
                    debit.push (<div className='compTotal' key={"dl" + ic}>
                        <div className='companyLine'>
                            <div className='caption'>{comp[0].accountEntry.CompanyDetail?.CompanyName}</div>
                            <div className='amount'>{that.numberWithSpaces(companyTotal)}</div>
                        </div>
                        {lines}
                    </div>)
            })

            that.props.plDetails.horusEntries.map (function (comp, ic) {
                var lines = []
                var companyTotal = 0
                
                comp.map (function (entry, icc) {
                    if (entry.isSales)
                    {
                        var val = (entry.costLine.Credit ? entry.costLine.Credit : 0) - (entry.costLine.Debit ? entry.costLine.Debit : 0)
                        creditTotal += val
                        companyTotal += val
                        lines.push (<div className='costLine' key={"dcl" + ic + "_" + icc}>
                                <div className='invoiceNumber'>{entry.accountEntry.DocumentNo}<div className='hoverHint'>Date: {entry.accountEntry.Date}<br/>
                                                                                                                        Last modified: {entry.accountEntry.LastModifiedDate}<br/>
                                                                                                                        Confirmed:{entry.accountEntry.Confirmed ? "Yes":"No"}</div></div>
                                <div className='amount'>{that.numberWithSpaces(val)}</div>
                        </div>)
                    }
                })
                if (lines.length > 0)
                    credit.push (<div className='compTotal' key={"cl" + ic}>
                        <div className='companyLine'>
                            <div className='caption'>{comp[0].accountEntry.CompanyDetail?.CompanyName}</div>
                            <div className='amount'>{that.numberWithSpaces(companyTotal)}</div>
                        </div>
                        {lines}
                    </div>)
            })

            that.props.plDetails.plExtraLines.map (function (line, ic) {
                var item = <div className='companyLine customLine' key={"customLine" + ic}>
                    <div className='caption'>
                    <Button variant='link' onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint':'deletePLLine', 'plLineId':line.id}))}}><i className={"fs-4 bi-trash"}></i></Button>
                        {line.caption}</div>
                    <div className='amount'>{that.numberWithSpaces(line.amount)}</div>
                </div>
                if (line.amount > 0)
                {
                    creditTotal += line.amount
                    credit.push (item)
                }
                else
                {
                    debTotal += line.amount
                    debit.push (item)
                }
            })
        }

        
        return <Modal className='eventEditionPLModal' size={'xl'}  show={true} onHide={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "plEditionId", "paramValue": null})}}>
                <Modal.Header closeButton>
                    <Modal.Title>{LABEL_EVENT} P&L</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <h4>Sales</h4>
                            {credit}
                            <div className='total'>
                                Total: {that.numberWithSpaces (creditTotal)}
                            </div>
                        </Col>
                        <Col sm={6}>
                            <h4>Costs</h4>
                            {debit}
                            <div className='total'>
                                Total: {that.numberWithSpaces (debTotal)}
                            </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className='total'>
                                Full total: {that.numberWithSpaces (creditTotal + debTotal)}
                            </div>
                        </Col>
                    </Row>
                    <Row className='noPrint'>
                        <Col md={12}>
                            <h4 className='mt-3'>Add an additional line</h4>
                            <div className='newLine'>
                                <div>
                                    <input
                                        className="form-control"
                                        name="newLineCaption"
                                        onChange={that.handleInputChange}
                                        value={that.state.newLineCaption}
                                        type="text"
                                        placeholder='Line caption'
                                    />
                                </div>
                                <div>
                                    <input
                                        className="form-control"
                                        name="newLineAmount"
                                        onChange={that.handleInputChange}
                                        value={that.state.newLineAmount}
                                        type="number"
                                        placeholder='Line caption'
                                    />
                                </div>
                                <div>
                                    <Button variant='primary' onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint':'addPLLine', 'amount':that.state.newLineAmount, 'caption':that.state.newLineCaption, 'editionId': that.props.interface.plEditionId}))}}>Add</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer className='noPrint'>
                <Button variant="primary" onClick={(e)=>{that.props.dispatch ({type:MODIFY_INTERFACE_PARAM, "paramName": "plEditionId", "paramValue": null})}}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        plDetails: state.data.plDetails,
    }
}

export default connect(mapStateToProps)(EventEditionPLModal)